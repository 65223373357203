:root {
  --primary-color: #0463CA;
  --info-color: #C4C4C4;
  --muted-color: #E5E5E5;
  --border-color: #e6e6e6;
  --green-color: #206D1E;
  --red-color:#e63900 ;
  --black-color: #000000;
  --white-color: #ffffff;
  --label-color: #3a99f8;
  --yellow-color: #faad14;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* overide antd sider width */
.ant-layout-sider {
  flex: 0 0 300px !important;
  max-width: 300px !important;
}
.ant-layout-sider-collapsed{
  flex: 0 0 80px !important;
  max-width: 80px !important;
}

.ant-card-body {
  padding: 5px !important;
}
.ant-card-meta-title {
  text-align: center !important;
}
.ant-card-meta-description{
  text-align: center !important;
  color: #0771DE !important;
}
.ant-input-group-addon {
  padding:5px 5px !important;
  color: #096dd9 !important;
  border: 0 !important;
  background-color: transparent !important;
}


/* label style override */
.ant-form-item-no-colon{
  color: var(--primary-color) !important;
  margin-top: 4px !important;
  margin-right: 4px !important;
  border-radius: 5px;
  width: 180px;
  font-size: medium !important;
}

.red {
  /* color: var(--red-color); */
  background-color:  var(--red-color);
}
.yellow {
  /* color: #ff9800; */
  background-color: #ff9800;
}

.ant-table .ant-table-tbody > tr.red:hover > td {
  background-color: var(--red-color) !important;
  color: #fff;
}

.ant-table .ant-table-tbody > tr.yellow:hover > td {
  background-color: #ff9800 !important;
  color: #fff;
}




/* .ant-table .ant-table-tbody > tr:hover > td {
  background: unset !important;
} */


